
import { Component, Vue } from 'vue-property-decorator'
import timeFormat from '@/utils/timeFormat' //时间过滤器
import { transactionDetails } from '@/api/financing'
import { loadingText } from '@/utils/defaultGoalConfig'
interface Info {
  [key: string]: string | number
}

@Component({
  filters: {
    formatTime: (time: any) => {
      if (time) return timeFormat(new Date(time))
    }
  }
})
export default class TradeDetails extends Vue {
  private loadingTxt: string = loadingText
  private loading: boolean = false
  private tradeId = ''
  private othserInfo: any = {}
  private tradeState: any
  private leaveName: string = 'FinancialDetail'
  get bankCard() {
    let returnVal: string = ''
    if (this.othserInfo.payOpenBank) {
      returnVal += this.othserInfo.payOpenBank
    }
    let payAccount: any = this.othserInfo.payAccount
    if (payAccount) {
      if (payAccount.length >= 4) {
        payAccount = payAccount.slice(payAccount.length - 4)
      }
      returnVal += '(' + payAccount + ')'
    }
    return returnVal
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      if (['FinancialDetail', 'SettlementDetails'].indexOf(from.name) > -1) {
        vim.leaveName = from.name
        sessionStorage.setItem('leaveName', vim.leaveName)
      } else {
        let sLeaveName: any = sessionStorage.getItem('leaveName')
        if (sLeaveName) {
          vim.leaveName = sLeaveName
        }
      }
    })
  }
  created() {
    let params: any = this.$route.params
    if (params.id) {
      sessionStorage.setItem('tradeDetails', JSON.stringify(params))
    } else {
      params = JSON.parse(sessionStorage.getItem('tradeDetails') as any)
    }
    this.tradeId = params.id
    this.tradeState = params.tradeState
    if (!this.tradeId) return
    this.query()
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (['TradeReceiveList', 'TradePayList', 'TradeReceipt'].indexOf(to.name) == -1) {
      sessionStorage.removeItem('tradeDetails')
      sessionStorage.removeItem('leaveName')
    }
    next()
  }

  private jumpTheirList() {
    this.$router.push({ name: 'TradeReceiveList', params: { id: this.tradeId } })
  }

  private async query() {
    this.loading = true
    transactionDetails({ transactionId: this.tradeId })
      .then((res) => {
        let datas: any = res.data.data
        if (datas) {
          this.othserInfo = datas
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  private prev() {
    this.$router.push({
      name: 'TradeReceipt',
      params: { obj: JSON.stringify(this.othserInfo), id: this.tradeId }
    })
  }
}
