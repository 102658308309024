var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "trade-details",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c("div", { staticClass: "block" }, [
        _vm._m(0),
        _vm.othserInfo && (_vm.tradeState == 3 || _vm.tradeState == 2)
          ? _c(
              "div",
              { staticClass: "Printing" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.prev }
                  },
                  [_vm._v("预览凭证")]
                )
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("交易编号：")]),
            _c("span", [_vm._v(_vm._s(_vm.othserInfo.tradeNo))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("交易发起时间：")]),
            _c("span", [
              _vm._v(_vm._s(_vm._f("formatTime")(_vm.othserInfo.createdTime)))
            ])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("交易发起人：")]),
            _c("span", [_vm._v(_vm._s(_vm.othserInfo.userName))])
          ]),
          _vm.othserInfo.userDept
            ? _c("div", { staticClass: "item" }, [
                _c("span", [_vm._v("发起人部门：")]),
                _c("span", [_vm._v(_vm._s(_vm.othserInfo.userDept))])
              ])
            : _vm._e(),
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("服务类型：")]),
            _c("span", [_vm._v(_vm._s(_vm.othserInfo.type))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("用途：")]),
            _c("span", [_vm._v(_vm._s(_vm.othserInfo.resultMsg))])
          ]),
          _vm.othserInfo.approvalId
            ? _c("div", { staticClass: "item" }, [
                _c("span", [_vm._v("审批编号：")]),
                _c("span", [_vm._v(_vm._s(_vm.othserInfo.approvalId))])
              ])
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "block" }, [
        _vm._m(1),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v(" 计划收款人数目： ")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.othserInfo.transMemberCount
                    ? _vm.othserInfo.transMemberCount + "人"
                    : ""
                )
              )
            ])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("实际成功人数目：")]),
            _c(
              "span",
              {
                staticStyle: { color: "#409eff", cursor: "pointer" },
                on: { click: _vm.jumpTheirList }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.othserInfo.successCount
                        ? _vm.othserInfo.successCount + "人"
                        : "0人"
                    ) +
                    " "
                )
              ]
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "block" }, [
        _vm._m(2),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("付款金额：")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.othserInfo.amount ? _vm.othserInfo.amount + "元" : ""
                )
              )
            ])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("付款银行卡：")]),
            _c("span", [_vm._v(" " + _vm._s(_vm.bankCard) + " ")])
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "operate-btn" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: _vm.leaveName })
                }
              }
            },
            [_vm._v("返回")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "vertical-line" }),
      _c("span", { staticClass: "text" }, [_vm._v("交易发起信息")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "vertical-line" }),
      _c("span", { staticClass: "text" }, [_vm._v(" 收款人信息 ")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "vertical-line" }),
      _c("span", { staticClass: "text" }, [_vm._v("付款信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }